import "./App.css";
import React from "react";
import HeroSection from "./components/HeroSection";
import StartSection from "./components/StartSection";
import FirstSection from "./components/FirstSection";
import SecondSection from "./components/SecondSection";
import ThirdSection from "./components/ThirdSection";
import SponsorsSection from "./components/SponsorsSection";
import ClientsSection from "./components/ClientsSection";
import BeforeAfterSection from "./components/BeforeAfterSection";
import WhySeedStackSection from "./components/WhySeedStackSection";
import FooterSection from "./components/FooterSection";

function App() {
  return (
    <div className="w-full font-monsterrat">
      <HeroSection />
      <StartSection />
      {/* <FirstSection /> */}
      {/* <ThirdSection /> */}
      {/* <SecondSection /> */}
      {/* <SponsorsSection /> */}
      {/* <ClientsSection /> */}
      <BeforeAfterSection />
      <WhySeedStackSection />
      <FooterSection />
    </div>
  );
}

export default App;
