import React from "react";

const StartSection = () => {
  return (
    <>
      <div id="start" className="px-2  xl:px-24 lg:px-18  xl:py-14">
        <div className="max-w-[1395px] m-auto ">
          <div className="flex flex-col xl:flex-row justify-between border-dashed xl:border-b-2 xl:border-black xl:border-opacity-20 ">
            <p className="text-lg font-medium py-5 border-b border-black border-dashed border-opacity-20 xl:border-none xl:text-2xl p-5">
              Demora AI is the smart way to launch your product...
            </p>
          </div>
        </div>
        <div className="max-w-[1395px] m-auto rounded-lg p-5">
          <div className="flex flex-col xl:flex-row items-center gap-y-10 xl:gap-12 justify-between p-5 xl:mt-5">
            <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3 text-2xl mt-10">
              <p className="block w-100 text-md font-light">
                Did you Know{" "}
                <span className="underline decoration-yelo decoration-4">
                  95%
                </span>{" "}
                of New Products{" "}
                <span className="underline decoration-yelo decoration-4">
                  Fail
                </span>
                ?
              </p>
            </div>
            <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3">
              <img
                src="/img/Rectangle.png"
                className="w-48 h-auto"
                alt="Icon 1"
              />
              <p className="block w-100 text-md font-light xl:text-lg">
                That&apos;s why its Important to set the foundations for a
                successful product from the get go {" "}
              </p>
            </div>
            <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3">
              <img
                src="/img/Rectangle2.png"
                className="w-48 h-auto"
                alt="Icon 2"
              />
              <p className="block w-100 text-md font-light xl:text-lg">
                You need a plan that puts the customer at the front of the
                process
              </p>
            </div>
            <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3 mb-10">
              <img
                src="/img/Rectangle3.png"
                className="w-48 h-auto"
                alt="Icon 3"
              />
              <p className="block w-100 text-md font-light xl:text-lg">
                Demora AI helps you establish your digital presence and start
                attracting your first customers in minutes.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        id="whyUs"
        className="bg-white px-2 xl:px-64 lg:px-56 py-8  xl:py-14"
      >
        <div className="max-w-[1395px] m-auto ">
          <div className="flex flex-col xl:flex-row justify-between border-dashed xl:border-b-2 xl:border-black xl:border-opacity-20 ">
            <p className="text-lg font-medium py-5 border-b border-black border-dashed border-opacity-20 xl:border-none xl:text-2xl p-5">
              Why Demora AI?{" "}
            </p>
          </div>
        </div>
        <p className="text-md py-5 xl:text-lg font-light p-5 ">
          We are led by a team of Founders who know what early stage startups
          need to succeed.
        </p>
        <div className="p-5 flex flex-wrap gap-5 justify-center">
          <div className="text-center rounded-xl">
            <div className=" p-3 rounded-lg overflow-hidden w-auto">
              <img
                alt="placeholder"
                src="/img/asim.jpeg"
                className="w-72 rounded-full border-8 border-forest-green"
              ></img>
            </div>
            <p className="p-4">Asim (CEO - 3x Founder)</p>
          </div>
          <div className="text-center rounded-xl">
            <div className="p-3 rounded-lg overflow-hidden w-auto">
              <img
                alt="placeholder"
                src="/img/zoha.png"
                className="w-72 rounded-full border-8 border-bright-green"
              ></img>
            </div>
            <p className="p-4">Zoha (Startup Marketing Expert)</p>
          </div>
        </div>

        <p className="text-md  xl:text-lg font-light p-5 pt-14 ">
          We love startups but also have experience of working at top
          Engineering and Marketing firms. So you can trust us to deliver.
        </p>

        <div className="flex gap-10 justify-center items-center py-8 flex-wrap">
          <img alt="placeholder" src="/img/amazon.png" className="h-10"></img>
          <img alt="placeholder" src="/img/goldman.png" className="h-10"></img>
          <img alt="placeholder" src="/img/jpmorgan.svg" className="h-10"></img>
          <img alt="placeholder" src="/img/groupm.svg" className="h-10"></img>
          <img alt="placeholder" src="/img/publicis.png" className="h-16"></img>
          <img
            alt="placeholder"
            src="/img/manninggottlieb.svg"
            className="h-10"
          ></img>
        </div>

        <p className="text-md py-5 xl:text-lg font-light p-5 pt-14 ">
          We combine our Engineering, Marketing and Product expertise into a
          unified development strategy.
        </p>

        <div className="p-5 flex flex-wrap gap-5 justify-center">
          <div className="border-dashed border-2 p-4 rounded-lg">
            <p className="text-7xl xl:text-9xl">70+</p>
            <p className="text-2xl">Expert Contractors</p>
          </div>
          <div className="border-dashed border-2 p-4 rounded-lg">
            <p className="text-7xl xl:text-9xl">100+</p>
            <p className="text-2xl">Projects Completed</p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default StartSection;
