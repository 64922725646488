import React from "react";

const FooterSection = () => {
  return (
    <>
      <div className="bg-forest-green px-2 pt-14 pb-5 xl:pb-20   text-white  lg:px-56 xl:px-64">
        <div className="max-w-[1395px] m-auto flex flex-col justify-between xl:justify-between xl:flex-row ">
          <div className="xl:w-1/4">
            <img
              alt="placeholder"
              src="/img/whiteLogo.png"
              className="w-60 mb-4"
            ></img>
            {/* <div className="py-6 flex flex-row  gap-x-2">
              <a href="#">
                <img
                  alt="placeholder"
                  src="/img/facebook.png"
                  className="w-6 h-6"
                ></img>
              </a>
              <a href="#">
                <img
                  alt="placeholder"
                  src="/img/twitter.png"
                  className="w-6 h-6"
                ></img>
              </a>
              <a href="#">
                <img
                  alt="placeholder"
                  src="/img/youtube.png"
                  className="w-6 h-6"
                ></img>
              </a>
              <a href="#">
                <img
                  alt="placeholder"
                  src="/img/instagram.png"
                  className="w-6 h-6"
                ></img>
              </a>
            </div> */}
          </div>
          <div className="flex flex-row justify-between gap-x-20 xl:w-1/2">
            <div className="flex flex-col xl:w-1/2  ">
              <div className="mt-10 mb-3.5 xl:mt-0">
                <p className="text-green text-sm text-semibold">COMPANY</p>
              </div>
              <div className="mb-3.5">
                <p className=" text-xs ">
                  <a href="#start">About us</a>
                </p>
              </div>
              <div className="mb-3.5">
                <p className=" text-xs ">
                  <a href="#whyUs">Why us</a>
                </p>
              </div>
            </div>
            <div className="flex flex-col xl:w-1/2 ">
              <div className="mt-10 mb-3.5 xl:mt-0">
                <p className="text-green text-sm text-semibold">CONTACTS</p>
              </div>
              <div className="mb-3.5">
                <p className=" text-xs ">
                  483 Green Lanes, London, England, N13 4BS
                </p>
              </div>
              <div className="mb-3.5">
                <p className=" text-xs ">
                  <a
                    href="https://calendly.com/asimshaikh/seedstack"
                    target="_blank"
                  >
                    Book a call
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="text-beige mt-4">© 2024 Demora AI</p>
      </div>
    </>
  );
};

export default FooterSection;
