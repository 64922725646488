import React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const FirstSection = () => {
  return (
    <>
      {/* mobile section ul */}

      <div className="bg-forest-green block py-14 px-2 xl:px-64 lg:px56 text-white xl:my-28  ">
        <div className="max-w-[1395px] m-auto ">
          <div className="xl:flex xl:flex-row xl:justify-between">
            <div className="xl:w-1/3">
              <div className="border-b-2 border-dashed border-white border-opacity-20 text-lg font-medium py-5 xl:py-10 xl:text-2xl xl:w-[22.5rem] xl:h-auto xl:font-medium">
                <p>Without Demora AI </p>
              </div>
              <div className="flex flex-col gap-5 xl:gap-y-10 py-5 xl:py-10 ">
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg ">Uncertainty </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    Risky decision-making{" "}
                  </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    Unclear market value{" "}
                  </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    Wasteful use of resources{" "}
                  </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    A solution without a problem to solve or a clear impact{" "}
                  </p>
                </div>
              </div>
            </div>
            <img
              alt="placeholder"
              className="block l:hidden xl:hidden my-10 w-full flex items-center max-w-[500px]"
              src="\img\background-3-mobile.png"
            ></img>
            <img
              alt="placeholder"
              className="hidden l:block xl:block my-10  flex items-center"
              src="\img\background-3.png"
            ></img>
            <div className="xl:w-1/3">
              <div className="border-b-2 border-dashed border-white border-opacity-20 text-lg font-medium py-5 xl:py-10 xl:text-2xl xl:font-medium xl:w-[22.5rem] xl:h-auto">
                <p>With Demora AI</p>
              </div>
              <div className="flex flex-col gap-5 xl:gap-10 py-5 xl:py-10 ">
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg ">
                    Confidence in your Idea
                  </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    Informed decision-making
                  </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    Clear market value
                  </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    Good use of resources{" "}
                  </p>
                </div>
                <div className="flex flex-row  gap-x-2">
                  <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                    <img
                      alt=""
                      src="/img/Ellipse 1.png"
                      className="block !w-full !h-full"
                    ></img>
                  </div>
                  <p className="font-light text-sm xl:text-lg">
                    A solution that solves complex problems and makes an impact{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstSection;
