import React from "react";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { useState } from "react";
import { Link } from "@mui/material";
const HeroSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <div className="bg-forest-green px-32 py-3 text-white">
        <div className="px-2 hidden xl:flex flex-row justify-between">
          <div>
            <img src="/img/whiteLogo.png" alt="Logo" className="w-72" />
          </div>
          <div className="flex gap-x-12 lg:gap-x-16 items-center">
            {/* <a href="#services">Services</a> */}
            <a href="#start">About Us</a>
            <a href="#whyUs">Why us</a>
            <Link
              sx={{
                border: "1px solid #fff",
                cursor: "pointer",
                textDecoration: "none",
              }}
              href="https://calendly.com/asimshaikh/seedstack"
              target="_blank"
              className="text-white rounded-md shadow-none hover:shadow-none text-lg normal-case border-white py-2.5 px-11 rounded-none border-dashed right-green hover:text-bright-green"
            >
              Book a call
            </Link>
          </div>
        </div>
        {/* nav mobile */}
        <div
          className={`absolute w-screen h-screen top-0 left-0 bg-black/50 z-1 ${
            !isOpen ? "hidden" : ""
          }`}
          onClick={toggleMenu}
        ></div>
      </div>

      <div className="xl:hidden flex justify-between pb-4 px-4 bg-forest-green">
        <div className="w-52">
          <img src="/img/whiteLogo.png" alt="Logo" />
        </div>
        <div
          className={classNames("hover:cursor-pointer", {
            "h-8": !isMenuOpen,
            "w-8": !isMenuOpen,
            "h-6": isMenuOpen,
            "w-6": isMenuOpen,
          })}
          onClick={toggleMenu}
        >
          <img
            src={isMenuOpen ? "/img/close.png" : "/img/Menu.png"}
            alt="Menu"
          />
        </div>
        <div
          className={classNames(
            "xl:hidden w-full md:w-[430px] md:border-l-gray-300 md:border-l flex flex-col absolute right-0 mt-4 top-full bg-gray-50 overflow-hidden transition-max-h",
            {
              "max-h-0": !isMenuOpen,
              "max-h-screen": isMenuOpen,
            }
          )}
          style={{ transitionDuration: "300ms" }}
        >
          <nav className="xl:px-20 mt-2 overflow-auto font-helvetica-roman font-medium">
            <ul className="xl:gap-12 block flex-col items-center text-center text-gray-600">
              <li className="block py-2 border-b-2 border-dashed border-b-2 border-dashed-bright-green">
                <a href="#start">About Us</a>
              </li>
              <li className="block py-2 border-b-2 border-dashed border-b-2 border-dashed-bright-green">
                <a href="#whyUs">Why us</a>
              </li>
              <li className="block py-2 border-b-2 border-dashed border-b-2 border-dashed-bright-green">
                <a
                  href="https://calendly.com/asimshaikh/seedstack"
                  target="_blank"
                >
                  Book a call
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="bg-white px-2 w-full opacity-0.5 lg:px-24 xl:px-44 items-center pt-2 pb-6 relative overflow-hidden">
        <div className="max-w-[1395px] m-auto ">
          {/* nav desktop */}
          <div
            className={`pb-5 w-66vw flex flex-col absolute h-1/2 bg-forest-green text-white transition-all top-0 z-5 ${
              isOpen ? "right-0" : "-right-full"
            }`}
          >
            <div className="flex flex-row-reverse">
              <div className="mt-2 mr-2 w-4 h-4" onClick={toggleMenu}>
                <img src="/img/close.png"></img>
              </div>
            </div>
            <div className="flex flex-grow flex-col justify-between mt-6 px-7">
              <div className="flex flex-col gap-y-10">
                <div>
                  <img src="/img/whiteLogo.png" className="h-7 w-44"></img>
                </div>

                <ul className="flex flex-col gap-y-5">
                  <li className="cursor-pointer text-lg font-medium">
                    <a href="#services">Services</a>
                  </li>
                  <li className="cursor-pointer text-lg font-medium">
                    <a href="#whyUs">Why us</a>
                  </li>
                </ul>
              </div>
              <div className="flex justify-center">
                <Link
                  href="https://calendly.com/asimshaikh/seedstack"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    className="w-44 h-11 normal-case bg-bright-green text-white font-bold rounded-none shadow-none hover:bg-bright-green hover:shadow-none "
                  >
                    Book a call
                  </Button>
                </Link>
              </div>
            </div>
            {/* <div className="flex flex-row mb-16 mt-5 mx-5">
              <a href="#">
                <img
                  alt="placeholder"
                  src="/img/facebook-white.png"
                  className="w-7 h-7"
                ></img>
              </a>
              <a href="#">
                <img
                  alt="placeholder"
                  src="/img/twitter-white.png"
                  className="w-7 h-7"
                ></img>
              </a>
              <a href="#">
                <img
                  alt="placeholder"
                  src="/img/instagram-white.png"
                  className="w-7 h-7"
                ></img>
              </a>
            </div> */}
          </div>
          <div className=" z-1 ">
            {/* hero section */}
            <div className="pt-14 xl:pt-44 p-2 z-1 flex flex-wrap w-full">
              <div className="w-full md:w-2/5 lg:w-1/4 grow ">
                <h1 className="text-black text-5xl xl:text-6xl mb-10  font-semibold leading-normal">
                  <span className="underline decoration-yelo decoration-4">
                    One-Stop
                  </span>{" "}
                  Innovation{" "}
                  <span className="underline decoration-yelo decoration-4">
                    Hub
                  </span>
                </h1>
                <div className="mb-10">
                  <p className="bg-bright-green text-white px-4 py-1 text-2xl xl:text-3xl font-medium text-center">
                    For Early-Stage Startups
                  </p>
                </div>
                <div className="mb-14">
                  <p className="text-black text-lg mt-10 xl:mt-0 xl:text-2xl font-medium">
                    Demora AI provides everything you need to validate and
                    market your product, under one roof
                  </p>
                </div>

                <div className="pb-20 xl:pb-0 flex flex-row items-center justify-center	text-center gap-y-6 xl:mt-36 mt-5 hidden xl:block">
                  <div>
                    <Link
                      sx={{
                        p: 2,
                        textAlign: "center",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      href="https://calendly.com/asimshaikh/seedstack"
                      target="_blank"
                      className="shadow-none hover:shadow-none text-white rounded-lg bg-forest-green normal-case w-full text-base font-medium xl:w-auto xl:py-3.5 xl:px-11 xl:text-lg hover:bg-forest-green"
                    >
                      See how we can help you
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-3/5 xl:w-1/3 grow">
                <img
                  className="ml-auto"
                  alt="placeholder"
                  src="\img\heroBackgroundUpdated.png"
                ></img>
              </div>
            </div>
            <div className="pb-20 xl:pb-0 flex flex-row items-center justify-center	text-center gap-y-6 mt-16 xl:hidden">
              <div>
                <Link
                  sx={{
                    p: 2,
                    textAlign: "center",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  href="https://calendly.com/asimshaikh/seedstack"
                  target="_blank"
                  className="shadow-none hover:shadow-none text-white rounded-lg bg-forest-green normal-case w-full text-base font-medium xl:w-auto xl:py-3.5 xl:px-11 xl:text-lg hover:bg-forest-green"
                >
                  See how we can help you
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
