import React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const WhySeedStackSection = () => {
  return (
    <>
      <div className="bg-white px-2 p-14  text-black lg:px-54 xl:px-64 xl:my-28 xl:p-0 ">
        <div className="max-w-[1395px] m-auto flex flex-col justify-between   ">
          <p
            id="whyUs"
            className="pb-5 mb-5 border-b-2border-dashed text-medium-black font-medium text-lg"
          >
            Why Demora AI?
          </p>
          <div className="mb-10 xl:mb-15 xl:flex xl:flex-row xl:justify-between xl:gap-y-7">
            <div className="mb-5 flex flex-col  xl:w-1/4 ">
              <div className="mb-5">
                <p className="text-medium-black font-medium text-lg">
                  Fastest way to Launch
                </p>
              </div>
              <div>
                <p className="text-sm font-light">
                  Demora AI is the fastest way to launch your product with an
                  Intent driven AI builder that helps you get started in
                  minutes.
                </p>
              </div>
            </div>
            <div className="mb-5 flex flex-col  xl:w-1/4 ">
              <div className="mb-5">
                <p className="text-medium-black font-medium text-lg">
                  Learns as you go
                </p>
              </div>
              <div>
                <p className="text-sm font-light">
                  Our model learns about your business as you go, so you can get
                  better results over time.
                </p>
              </div>
            </div>
            <div className="mb-5 flex flex-col  xl:w-1/4 ">
              <div className="mb-5">
                <p className="text-medium-black font-medium text-lg">
                  Cost-effective
                </p>
              </div>
              <div>
                <p className="text-sm  font-light">
                  Its 10x cheaper than hiring a team of developers and
                  designers.
                </p>
              </div>
            </div>
          </div>

          <Link
            sx={{
              border: "1px solid #fff",
              cursor: "pointer",
              textDecoration: "none",
              textAlign: "center",
            }}
            href="https://calendly.com/asimshaikh/seedstack"
            target="_blank"
            className="shadow-none hover:shadow-none  py-2.5 l:px-10 xl:px-10 rounded-none bg-bright-green text-white hover:bg-bright-green normal-case font-bold text-base leading-6 xl:self-center "
          >
            Book a no-obligation call today
          </Link>
        </div>
      </div>
    </>
  );
};

export default WhySeedStackSection;
